
class searchFrom {
  constructor(type) {
    if (type === 'form') {
      this.menuId = ''
      this.parentId = '0'
      this.deptName = ''
      this.leader = ''
      this.phone = ''
      this.email = ''
      this.orderNum = ''
      this.status = ''
    } else if (type === 'rule') {
      const validatePhone = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入电话'))
        } else {
          if (!/^1[3456789]\d{9}$/.test(value)) {
            callback(new Error('格式错误'))
          }
          callback()
        }
      }
      const validateEamil = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入邮箱'))
        } else {
          // eslint-disable-next-line
          if (!/^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/.test(value)) {
            callback(new Error('格式错误'))
          }
          callback()
        }
      }
      this.parentId = [
        {
          required: true,
          message: '请选择上级部门',
          trigger: 'blur',
        },
      ]
      this.deptName = [
        {
          required: true,
          message: '请输入部门名称',
          trigger: 'blur',
        },
      ]
      this.orderNum = [
        {
          required: true,
          message: '请输入顺序数字',
          trigger: 'blur',
        },
      ]
      this.leader = [
        {
          required: true,
          message: '请输入负责人',
          trigger: 'blur',
        },
      ]
      this.phone = [
        {
          required: true,
          validator: validatePhone,
          trigger: 'blur',
        },
      ]
      this.email = [
        {
          required: true,
          validator: validateEamil,
          trigger: 'blur',
        },
      ]
      this.status = [
        {
          required: true,
          message: '请选择状态',
          trigger: 'blur',
        },
      ]
    }
  }
}
export default searchFrom
