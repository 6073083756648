<template>
  <div class="wrap">
    <div class="search-container">
      <el-row>
          <el-form ref="form" :model="form" label-width="80px">
            <el-col :span="6">
              <el-form-item label="部门名称">
                <el-input clearable placeholder="请输入" v-model="form.deptName"/>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="状态">
                <el-select placeholder="请选择" clearable v-model="form.visible">
                  <el-option value="" label="全部"></el-option>
                  <el-option value="0" label="正常"></el-option>
                  <el-option value="1" label="停用"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-form>
        <el-col :span="12" style="text-align:right">
          <el-button @click="searchEvent" class="search-button">{{$t('search')}}</el-button>
          <el-button @click="cleanForm" class="clean-button">{{$t('clean')}}</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="table-container">
      <el-table
        :data="tableData"
        style="width: 100%;margin-bottom: 20px;"
        row-key="deptId"
        border
        :height="tableHeight"
        @sort-change='sort_change'
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
        <el-table-column
          prop="deptName"
          label="部门名称"
          header-align="center">
        </el-table-column>
        <el-table-column
          prop="orderNum"
          label="排序"
          align="center">
        </el-table-column>
        <el-table-column
          prop="status"
          label="状态"
          align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.status | statusFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          sortable='custom'
          align="center">
        </el-table-column>
        <el-table-column
          align="center"
          width="350"
          :label="$t('option')">
          <template slot-scope="scope">
            <!-- <el-button class="default-button" @click="changeAddDialog(true, false, scope.row)">{{$t('add')}}</el-button> -->
            <el-button class="default-button" v-if="buttonAuth.includes('system:dept:edit')" @click="changeAddDialog(true, true, scope.row)">{{$t('edit')}}</el-button>
            <el-button class="default-button" v-if="buttonAuth.includes('system:dept:remove')" @click="openDeleteDialog([scope.row.deptId])">{{$t('delete')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="button-wrapper">
      <el-button class="default-button" v-if="buttonAuth.includes('system:dept:add')" @click="changeAddDialog(true, false)">{{$t('add')}}</el-button>
    </div>
    <!-- 添加/修改弹窗 -->
    <add-dialog ref="addDialog" :Language="Language" :show="addDialog.visible" :isEdit="addDialog.isEdit" @closeDialog="changeAddDialog"></add-dialog>
    <!-- 删除确认弹窗 -->
    <confirm-dialog ref="confirmDialog" :Language="Language" :show="confirmDialog.visible" :type="confirmDialog.type" @sureDialog="sureConfirmDialog" @closeDialog="changeConfirmDialog"></confirm-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import apis from '@/apis'
import mixin from '@/mixins/index'
import confirmDialog from '@/components/confirmDialog.vue'
import treeData from '@/unit/treeutil'
import UserSearchClass from './userSearchClass'
import addDialog from './dialog/addDialog.vue'

export default {
  mixins: [mixin],
  components: {
    addDialog,
    confirmDialog,
  },
  data() {
    return {
      form: new UserSearchClass('form'), // 实例化一个表单的变量
      tableData: [],
      addDialog: {
        visible: false,
        isEdit: false,
      },
      confirmDialog: {
        visible: false,
        type: 1,
        data: {},
      },
    }
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        '1': '停用',
        '0': '正常',
      }
      return statusMap[status]
    },
    menuTypeFilter(type) {
      const menuMap = {
        'M': '目录',
        'F': '按钮',
        'C': '菜单',
      }
      return menuMap[type]
    },
  },
  computed: {
    ...mapState(['Language']),
  },
  methods: {
    // 用来表头排序
    sort_change({ prop, order }) {
      const changeText = {
        ascending: "asc",
        descending: "desc",
      }
      this.form.sortField = order ? prop : null;
      this.form.sortOrder = changeText[order]
      this.searchEvent()
    },
    /**
     * @function 搜索事件
     */
    searchEvent() {
      const params = {}
      for (const i in this.form) {
        if (this.form[i] !== '') {
          params[i] = this.form[i]
        }
      }
      this.$http.get(apis.dept_list, { params }).then((res) => {
        if (res.data.code === 0) {
          if (res.data.rows.length === 1) {
          this.tableData = res.data.rows
          } else {
            this.tableData = treeData(res.data.rows, 'deptId')
          }
        }
      })
    },

    /**
     * @function 清空搜索栏
     */
    cleanForm() {
      this.form = new UserSearchClass('form')
      this.$nextTick(() => {
        this.searchEvent()
      })
    },
    /**
     * @function 改变新增/修改弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Boolean} isEdit 是否是修改弹窗，如果是则要调用接口，进行回显
     * @param {Object} data 修改回显时，传给后端的值
     */
    changeAddDialog(dialogStatus, isEdit = false, data) {
      if (dialogStatus) {
        this.addDialog.isEdit = isEdit
        if (isEdit) {
          this.$nextTick(() => {
            this.$refs['addDialog'].getDataQuery(data)
          })
        }
        this.$nextTick(() => {
          this.$refs['addDialog'].getPermissions()
        })
      } else {
        this.searchEvent()
      }
      // 这么写才不会影响最后的初始化，如果赋值完直接打开，会影响最后的初始化
      this.$nextTick(() => {
        this.addDialog.visible = dialogStatus || false
      })
    },
    /**
     * @function 打开删除弹窗
     * @param {Array} id 选中的ID数组
     */
    openDeleteDialog(id) {
      if (id.length === 0) {
        this.$message.error('请先选择数据')
        return
      }
      this.confirmDialog.data.ids = id.join(',')
      this.changeConfirmDialog(true, 1)
    },
    /**
     * @function 改变确认/删除弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Number} type 打开的弹窗类型，1为删除确认
     */
    changeConfirmDialog(dialogStatus, type = 1) {
      this.confirmDialog.type = type
      this.confirmDialog.visible = dialogStatus
      if (!dialogStatus) {
        this.confirmDialog.data.ids = ''
        this.searchEvent()
      }
    },
    /**
     * @function 当确认弹窗点击确定的时候触发
     */
    sureConfirmDialog() {
      // 如果一个页面有多个弹窗，视情况决定用if语句还是二元表达式对api进行判断
      this.$http.post(apis.dept_remove + this.confirmDialog.data.ids).then((res) => {
        if (res.data.code === 0) {
          this.$refs.confirmDialog.closeDialog()
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
@import "../../../assets/styles/mixin.scoped.less";
.button-wrapper {
  text-align: left;
  padding-left: 20px;
}
</style>
