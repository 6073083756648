
class searchFrom {
  constructor(type) {
    if (type === 'form') {
      this.menuKey = ''
      this.menuName = ''
      this.visible = ''
    }
  }
}
export default searchFrom
